/* Modernizr 2.7.1 (Custom Build) | MIT & BSD
 * Build: http://modernizr.com/download/#-video-touch-shiv-cssclasses-teststyles-prefixes-cssclassprefix:w!mod!
 */
;
window.Modernizr = function (a, b, c) {
	function w(a) {
		j.cssText = a
	}

	function x(a, b) {
		return w(m.join(a + ";") + (b || ""))
	}

	function y(a, b) {
		return typeof a === b
	}

	function z(a, b) {
		return !!~("" + a).indexOf(b)
	}

	function A(a, b, d) {
		for (var e in a) {
			var f = b[a[e]];
			if (f !== c) return d === !1 ? a[e] : y(f, "function") ? f.bind(d || b) : f
		}
		return !1
	}
	var d = "2.7.1",
		e = {},
		f = !0,
		g = b.documentElement,
		h = "modernizr",
		i = b.createElement(h),
		j = i.style,
		k, l = {}.toString,
		m = " -webkit- -moz- -o- -ms- ".split(" "),
		n = {},
		o = {},
		p = {},
		q = [],
		r = q.slice,
		s, t = function (a, c, d, e) {
			var f, i, j, k, l = b.createElement("div"),
				m = b.body,
				n = m || b.createElement("body");
			if (parseInt(d, 10))
				while (d--) j = b.createElement("div"), j.id = e ? e[d] : h + (d + 1), l.appendChild(j);
			return f = ["&#173;", '<style id="s', h, '">', a, "</style>"].join(""), l.id = h, (m ? l : n).innerHTML += f, n.appendChild(l), m || (n.style.background = "", n.style.overflow = "hidden", k = g.style.overflow, g.style.overflow = "hidden", g.appendChild(n)), i = c(l, a), m ? l.parentNode.removeChild(l) : (n.parentNode.removeChild(n), g.style.overflow = k), !!i
		},
		u = {}.hasOwnProperty,
		v;
	!y(u, "undefined") && !y(u.call, "undefined") ? v = function (a, b) {
		return u.call(a, b)
	} : v = function (a, b) {
		return b in a && y(a.constructor.prototype[b], "undefined")
	}, Function.prototype.bind || (Function.prototype.bind = function (b) {
		var c = this;
		if (typeof c != "function") throw new TypeError;
		var d = r.call(arguments, 1),
			e = function () {
				if (this instanceof e) {
					var a = function () {};
					a.prototype = c.prototype;
					var f = new a,
						g = c.apply(f, d.concat(r.call(arguments)));
					return Object(g) === g ? g : f
				}
				return c.apply(b, d.concat(r.call(arguments)))
			};
		return e
	}), n.touch = function () {
		var c;
		return "ontouchstart" in a || a.DocumentTouch && b instanceof DocumentTouch ? c = !0 : t(["@media (", m.join("touch-enabled),("), h, ")", "{#modernizr{top:9px;position:absolute}}"].join(""), function (a) {
			c = a.offsetTop === 9
		}), c
	}, n.video = function () {
		var a = b.createElement("video"),
			c = !1;
		try {
			if (c = !!a.canPlayType) c = new Boolean(c), c.ogg = a.canPlayType('video/ogg; codecs="theora"').replace(/^no$/, ""), c.h264 = a.canPlayType('video/mp4; codecs="avc1.42E01E"').replace(/^no$/, ""), c.webm = a.canPlayType('video/webm; codecs="vp8, vorbis"').replace(/^no$/, "")
		} catch (d) {}
		return c
	};
	for (var B in n) v(n, B) && (s = B.toLowerCase(), e[s] = n[B](), q.push((e[s] ? "" : "no-") + s));
	return e.addTest = function (a, b) {
			if (typeof a == "object")
				for (var d in a) v(a, d) && e.addTest(d, a[d]);
			else {
				a = a.toLowerCase();
				if (e[a] !== c) return e;
				b = typeof b == "function" ? b() : b, typeof f != "undefined" && f && (g.className += " w-mod-" + (b ? "" : "no-") + a), e[a] = b
			}
			return e
		}, w(""), i = k = null,
		function (a, b) {
			function l(a, b) {
				var c = a.createElement("p"),
					d = a.getElementsByTagName("head")[0] || a.documentElement;
				return c.innerHTML = "x<style>" + b + "</style>", d.insertBefore(c.lastChild, d.firstChild)
			}

			function m() {
				var a = s.elements;
				return typeof a == "string" ? a.split(" ") : a
			}

			function n(a) {
				var b = j[a[h]];
				return b || (b = {}, i++, a[h] = i, j[i] = b), b
			}

			function o(a, c, d) {
				c || (c = b);
				if (k) return c.createElement(a);
				d || (d = n(c));
				var g;
				return d.cache[a] ? g = d.cache[a].cloneNode() : f.test(a) ? g = (d.cache[a] = d.createElem(a)).cloneNode() : g = d.createElem(a), g.canHaveChildren && !e.test(a) && !g.tagUrn ? d.frag.appendChild(g) : g
			}

			function p(a, c) {
				a || (a = b);
				if (k) return a.createDocumentFragment();
				c = c || n(a);
				var d = c.frag.cloneNode(),
					e = 0,
					f = m(),
					g = f.length;
				for (; e < g; e++) d.createElement(f[e]);
				return d
			}

			function q(a, b) {
				b.cache || (b.cache = {}, b.createElem = a.createElement, b.createFrag = a.createDocumentFragment, b.frag = b.createFrag()), a.createElement = function (c) {
					return s.shivMethods ? o(c, a, b) : b.createElem(c)
				}, a.createDocumentFragment = Function("h,f", "return function(){var n=f.cloneNode(),c=n.createElement;h.shivMethods&&(" + m().join().replace(/[\w\-]+/g, function (a) {
					return b.createElem(a), b.frag.createElement(a), 'c("' + a + '")'
				}) + ");return n}")(s, b.frag)
			}

			function r(a) {
				a || (a = b);
				var c = n(a);
				return s.shivCSS && !g && !c.hasCSS && (c.hasCSS = !!l(a, "article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}mark{background:#FF0;color:#000}template{display:none}")), k || q(a, c), a
			}
			var c = "3.7.0",
				d = a.html5 || {},
				e = /^<|^(?:button|map|select|textarea|object|iframe|option|optgroup)$/i,
				f = /^(?:a|b|code|div|fieldset|h1|h2|h3|h4|h5|h6|i|label|li|ol|p|q|span|strong|style|table|tbody|td|th|tr|ul)$/i,
				g, h = "_html5shiv",
				i = 0,
				j = {},
				k;
			(function () {
				try {
					var a = b.createElement("a");
					a.innerHTML = "<xyz></xyz>", g = "hidden" in a, k = a.childNodes.length == 1 || function () {
						b.createElement("a");
						var a = b.createDocumentFragment();
						return typeof a.cloneNode == "undefined" || typeof a.createDocumentFragment == "undefined" || typeof a.createElement == "undefined"
					}()
				} catch (c) {
					g = !0, k = !0
				}
			})();
			var s = {
				elements: d.elements || "abbr article aside audio bdi canvas data datalist details dialog figcaption figure footer header hgroup main mark meter nav output progress section summary template time video",
				version: c,
				shivCSS: d.shivCSS !== !1,
				supportsUnknownElements: k,
				shivMethods: d.shivMethods !== !1,
				type: "default",
				shivDocument: r,
				createElement: o,
				createDocumentFragment: p
			};
			a.html5 = s, r(b)
		}(this, b), e._version = d, e._prefixes = m, e.testStyles = t, g.className = g.className.replace(/(^|\s)no-js(\s|$)/, "$1$2") + (f ? " w-mod-js w-mod-" + q.join(" w-mod-") : ""), e
}(this, this.document);
/**
 * Custom tests
 */
Modernizr.addTest('ios', /(ipod|iphone|ipad)/i.test(navigator.userAgent));
